import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export class IndexFeedText {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      gsap.to('.js-youtube_text', {
        x: -400,
        scrollTrigger: {
          trigger: '.js-move_wrap', //アニメーションが始まるトリガーとなる要素
          start: 'top bottom', //アニメーションが始まる位置
          end: 'bottom top', //アニメーションが終わる位置
          scrub: 0.2, //スクロール量に合わせてアニメーションが進む（数字も指定できる）
        },
      })
      gsap.to('.js-books_text', {
        x: 400,
        scrollTrigger: {
          trigger: '.js-move_wrap', //アニメーションが始まるトリガーとなる要素
          start: 'top bottom', //アニメーションが始まる位置
          end: 'bottom top', //アニメーションが終わる位置
          scrub: 0.2, //スクロール量に合わせてアニメーションが進む（数字も指定できる）
        },
      })
    })
  }
}
