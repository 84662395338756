export class ScrollSp {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      const target = document.querySelector('.js-scroll_sp')
      const targetArea = document.querySelector('.js-scroll_area_sp')
      if (!target) return
      target.addEventListener('touchmove', (e) => {
        const elm = e.currentTarget
        elm.classList.add('is-hide')
      })
      targetArea.addEventListener('touchmove', (e) => {
        const elm = e.currentTarget.querySelector('.js-scroll_sp')
        elm.classList.add('is-hide')
      })
    })
  }
}
