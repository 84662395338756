export class OverviewAccordion {
  constructor() {
    const trg = document.querySelectorAll('.js-overview_acco_trg')
    trg.forEach((data) => {
      data.addEventListener('click', (e) => {
        const text = data.querySelector('.js-trg_text')
        const target = e.currentTarget
        const prev = data.previousElementSibling
        const top = prev.getBoundingClientRect().top
        const elemtop = top + window.pageYOffset

        prev.classList.toggle('is-act')
        target.classList.toggle('is-act')
        if (target.classList.contains('is-act')) {
          text.textContent = '閉じる'
        } else {
          text.textContent = 'もっと見る'
          window.scroll({
            top: elemtop,
            behavior: 'smooth',
          })
        }
      })
    })
  }
}
