export class CountUp {
  constructor() {
    const getDecimalPointLength = (n) => {
      return (String(n).split('.')[1] || '').length
    }
    document.addEventListener('DOMContentLoaded', function () {
      if (document.getElementsByClassName('js-count_up').length == 0) return
      const boxes = document.querySelectorAll('.js-count_up')
      const defaultMargin = '-35%' // ここでデフォルトを書き換え
      const keys = []
      const elements = {}

      const doWhenIntersect = function (entries) {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (!entry.target.classList.contains('is-visible')) {
              const counterEle = entry.target.querySelector('.js-count')
              const from = parseFloat(entry.target.dataset.from || 0)
              const to = parseFloat(entry.target.dataset.to || 0)
              const duration = parseInt(entry.target.dataset.duration) || 1500

              if (!Number.isFinite(from) || !Number.isFinite(to) || from > to) {
                return false
              }

              const increment = to - from
              const deciamlPointLength = getDecimalPointLength(increment)
              const startTime = performance.now()

              const countUp = (timestamp) => {
                const elapsed = performance.now() - startTime
                const countValue = (from + (elapsed / duration) * increment).toFixed(deciamlPointLength)

                if (countValue >= to) {
                  counterEle.innerText = to.toLocaleString()
                } else {
                  counterEle.innerText = parseFloat(countValue).toLocaleString()
                  requestAnimationFrame(countUp)
                }
              }

              requestAnimationFrame(countUp)

              entry.target.classList.add('is-visible')
            }
          }
        })
      }

      boxes.forEach(function (box) {
        let key = box.getAttribute('data-margin')
        if (key !== null && key !== '' && key.match(/-*[0-9]*%/)) {
          if (key.replace('%', '') - 0 < -50) {
            key = '-50%'
            box.setAttribute('data-margin', key)
          }
          if (keys.indexOf(key) == -1) {
            keys.push(key)
            elements[key] = []
          }
          elements[key].push(box)
        } else {
          if (keys.indexOf(defaultMargin) == -1) {
            keys.push(defaultMargin)
            elements[defaultMargin] = []
          }
          elements[defaultMargin].push(box)
        }
      })

      keys.forEach(function (key) {
        const observer = new IntersectionObserver(doWhenIntersect, { root: null, rootMargin: key + ' 0px', threshold: 0 })
        elements[key].forEach(function (element) {
          observer.observe(element)
        })
      })
    })
  }
}
