import { UaCh } from '../foundation/UserAgent'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export class PhilosophyStickySlider {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      const container = document.querySelector('.js-philosophy_container')
      const list_wrap = document.querySelector('.js-philosophy_wrap')
      const mq = window.matchMedia('(min-width: 1025px)').matches //スマホ判定
      console.log(mq)
      if (UaCh.isMobile && !mq) return
      if (!container) return
      gsap.to(list_wrap, {
        x: () => -(list_wrap.clientWidth - container.clientWidth),
        ease: 'none',
        scrollTrigger: {
          trigger: '.js-philosophy_philosophy_trg',
          start: 'center center',
          end: () => `+=${list_wrap.clientWidth - container.clientWidth * 2}`,
          scrub: true,
          pin: true,
          anticipatePin: 1,
          invalidateOnRefresh: true,
          markers: false,
        },
      })
    })
  }
}
