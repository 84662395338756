export class Accordion {
  constructor() {
    if (document.querySelectorAll('.js-aco_trg').length == 0) return

    const getIndex = (element) => {
      if (!element) {
        return -1
      }
      let currentElement = element
      let index = 0
      while (currentElement.previousElementSibling) {
        index++
        currentElement = currentElement.previousElementSibling
      }
      return index
    }

    const acoTrg = document.querySelectorAll('.js-aco_trg')
    const slides = document.querySelectorAll('.js-company_slide')
    const move = (event) => {
      const index = getIndex(event.target)
      const target = event.currentTarget
      const type = event.type
      if (type === 'mouseenter') {
        slides.forEach((slide) => {
          if (slide.classList.contains('is-act')) {
            slide.classList.add('is-remove')
            slide.classList.remove('is-act')
          }
        })
        target.classList.add('is-act')
        slides[index].classList.add('is-act')
      } else if (type === 'mouseleave') {
        target.classList.remove('is-act')
        slides.forEach((slide) => {
          setTimeout(() => {
            slide.classList.remove('is-remove')
          }, 800)
        })
      }
    }
    const acoBlock = document.querySelectorAll('.js-aco_block')
    for (let i = 0; i < acoTrg.length; i++) {
      acoTrg[i].addEventListener('mouseenter', (event) => {
        move(event)
      })
      acoTrg[i].addEventListener('mouseleave', (event) => {
        move(event)
      })
    }
  }
}
