import { UaCh } from '../foundation/UserAgent'
export class FormSelectChoice {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      const searchParams = new URLSearchParams(window.location.search)
      const selects = document.querySelectorAll('.js-form_select')
      const checkLists = document.querySelectorAll('.js-form_choice_block')
      const selectsLists = document.getElementById('js-form_select')
      const recruitConsulting = document.getElementById('service_recruit_consulting')
      const training = document.getElementById('service_training')
      const hrTech = document.getElementById('service_hr_tech')
      const managementConsulting = document.getElementById('service_management_consulting')
      const envInfrastructure = document.getElementById('service_env_infrastructure')
      const company = document.getElementById('select_company')
      const position = document.getElementById('select_position')
      const school = document.getElementById('select_school')
      const uaStyle = UaCh.isMobile ? 'block' : 'flex'
      const selectData = sessionStorage.getItem('select')

      const addClass = (val) => {
        if (val == '人材採用コンサルティングについて') {
          recruitConsulting.classList.add('is-act')
        }
        if (val == '人材育成・社員研修について') {
          training.classList.add('is-act')
        }
        if (val == 'テクノロジー分野について') {
          hrTech.classList.add('is-act')
        }
        if (val == '経営・組織コンサルティングについて') {
          managementConsulting.classList.add('is-act')
        }
        if (val == 'コーポレートブランディングについて') {
          envInfrastructure.classList.add('is-act')
        }
        if (val == '採用について / 学生向けイベントについて') {
          school.style.display = uaStyle
          company.style.display = 'none'
          position.style.display = 'none'
        } else {
          company.style.display = uaStyle
          position.style.display = uaStyle
          school.style.display = 'none'
        }
      }

      if (searchParams.has('requests')) {
        const query = searchParams.get('requests')
        addClass(query)
      }

      if (selectData) {
        addClass(selectData)
      }

      if (!selects.length > 0) return
      selects.forEach((elm) => {
        elm.addEventListener('change', (e) => {
          checkLists.forEach((check) => {
            check.classList.remove('is-act')
          })
          const val = e.target.value
          sessionStorage.select = val
          addClass(val)
        })
      })
      const options = [...selectsLists.options]
      options.forEach((option) => {
        if (option.value === selectData) {
          option.selected = true
        }
      })
    })
  }
}
