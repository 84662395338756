import { createApp } from 'vue/dist/vue.esm-bundler.js'
import axios from 'axios'
export class VueSeminarFilter {
  constructor() {
    document.addEventListener('DOMContentLoaded', function () {
      this.el = document.getElementById('js-search_seminar')
      if (this.el === null) return
      axios.get('/wp-json/wp/v2/custom_seminar').then((res) => {
        createApp({
          data() {
            return {
              posts: res.data,
              seminar_type: vueSeminarType,
              seminar_cat: vueSeminarCat,
              currentYear: null,
              currentMonth: null,
              currentDay: null,
              selectYear: null,
              selectMonth: null,
              selectDay: null,
              startDay: null,
              endDay: null,
            }
          },
          methods: {
            toggleCheck(item) {
              item.checked = !item.checked
            },
            changeStartSelect(item) {
              this.startDay = new Date(this.currentYear, this.currentMonth - 1, this.currentDay)
            },
            changeEndSelect(item) {
              this.endDay = new Date(this.selectYear, this.selectMonth - 1, this.selectDay)
            },
            clearFiltering() {
              this.selectYear = null
              this.selectMonth = null
              this.selectDay = null
              this.startDay = null
              this.endDay = null
              this.seminar_type.forEach((term) => {
                term.checked = false
              })
              this.seminar_cat.forEach((term) => {
                term.checked = false
              })
            },
            getParam() {
              const now = new Date()
              const url = new URL(window.location.href)
              const params = url.searchParams
              const seminar_start_year = params.get('seminar_start_year')
              const seminar_start_month = params.get('seminar_start_month')
              const seminar_start_day = params.get('seminar_start_day')
              const seminar_end_year = params.get('seminar_end_year')
              const seminar_end_month = params.get('seminar_end_month')
              const seminar_end_day = params.get('seminar_end_day')
              this.currentYear = seminar_start_year ? seminar_start_year : now.getFullYear()
              this.currentMonth = seminar_start_month ? seminar_start_month : now.getMonth() + 1
              this.currentDay = seminar_start_day ? seminar_start_day : now.getDate()
              this.selectYear = seminar_end_year ? seminar_end_year : ''
              this.selectMonth = seminar_end_month ? seminar_end_month : ''
              this.selectDay = seminar_end_day ? seminar_end_day : ''
            },
          },
          mounted() {
            this.getParam()
            this.changeStartSelect()

            this.seminar_type.forEach((term) => {
              if (term.checked) {
                this.currentCat = term.slug
              }
            })
            this.seminar_cat.forEach((term) => {
              if (term.checked) {
                this.currentCat = term.slug
              }
            })
          },
          computed: {
            filteredPosts() {
              let posts = this.posts
              // フィルタリングの部分を関数にして呼ぶ
              const filtering = (post, keyStr, slugType) => {
                let isChecked = false
                let isDefault = true
                this[keyStr].forEach((cat) => {
                  if (cat.checked) {
                    isDefault = false
                    if (post[slugType].includes(cat.slug)) {
                      isChecked = true
                    }
                  }
                })
                return isDefault || isChecked
              }
              //日付フィルタリング
              const dayFiltering = (post) => {
                const targetDate = new Date(post.seminar_date)
                if (targetDate >= this.startDay && targetDate <= this.endDay) return true
              }

              posts = posts.filter((post) => filtering(post, 'seminar_type', 'type_slug'))
              posts = posts.filter((post) => filtering(post, 'seminar_cat', 'cat_slug'))
              posts = this.endDay !== null ? posts.filter((post) => dayFiltering(post)) : posts

              return posts.length
            },
          },
        }).mount(this.el)
      })
    })
  }
}
