export class GetColor {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      const root = document.querySelector(':root')
      const colorElm = document.getElementById('js-page_color')
      if (!colorElm) return
      const color = colorElm.getAttribute('data-color')
      root.style.setProperty('--current-color', color)
    })
  }
}
