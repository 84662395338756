import { UaCh } from '../foundation/UserAgent'
export class FixedSlideMenu {
  constructor() {
    const mq = window.matchMedia('(min-width: 768px)').matches //スマホ判定
    if (UaCh.isMobile && !mq) return
    const elm = document.querySelector('.js-sidebar')
    if (!elm) return

    const sectionList = document.querySelectorAll('.js-anchor_section')
    const addMenuList = () => {
      let html = ''
      const anchor = document.querySelector('.js-anchor')
      sectionList.forEach((data) => {
        const title = data.dataset.menu
        const hash = data.dataset.hash
        html += `<li><a class="js-anchor_item" href="#${hash}"><i class="c-ico"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="js-svg replaced-svg"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="currentColor" d="M443.7 266.8l-165.9 176C274.5 446.3 269.1 448 265.5 448c-3.986 0-7.988-1.375-11.16-4.156c-6.773-5.938-7.275-16.06-1.118-22.59L393.9 272H16.59c-9.171 0-16.59-7.155-16.59-15.1S7.421 240 16.59 240h377.3l-140.7-149.3c-6.157-6.531-5.655-16.66 1.118-22.59c6.789-5.906 17.27-5.469 23.45 1.094l165.9 176C449.4 251.3 449.4 260.7 443.7 266.8z"></path></svg></i>${title}</a></li>`
      })
      anchor.innerHTML = html
    }

    document.addEventListener('DOMContentLoaded', () => {
      addMenuList()
    })

    // アンカーと同期
    let isAnimation = false

    const options = {
      root: null,
      rootMargin: '-50% 0px',
      threshold: 0,
    }

    // 交差した時に実行する関数
    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (isAnimation) return
          activateIndex(entry.target)
        }
      })
    }

    const observer = new IntersectionObserver(callback, options)

    sectionList.forEach((section, i) => {
      observer.observe(section)
    })

    const anchorItem = document.querySelectorAll('.js-anchor_item')

    const activateIndex = (item) => {
      const currentActive = document.querySelector('.js-anchor_item.is-current')
      if (currentActive !== null) {
        currentActive.classList.remove('is-current')
      }
      const newActive = document.querySelector(`a[href='#${item.id}']`)
      newActive.classList.add('is-current')
    }

    const active = (item) => {
      item.classList.add('is-current')
      anchorItem.forEach((elm) => {
        if (elm !== item) elm.classList.remove('is-current')
      })
    }

    anchorItem.forEach((item) => {
      item.addEventListener('click', (event) => {
        const target = event.currentTarget
        isAnimation = true
        active(target)
        setTimeout(() => {
          isAnimation = false
        }, 500)
      })
    })

    window.addEventListener('load', () => {
      const target = document.querySelector('.js-anchor_trg')
      if (!elm) return
      const gbcrTop = target.getBoundingClientRect().top
      const y = window.pageYOffset + gbcrTop
      window.addEventListener('scroll', (e) => {
        const top = window.scrollY
        if (top > y) {
          elm.classList.add('is-act')
        } else {
          elm.classList.remove('is-act')
        }
      })
    })
  }
}
