import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
export class KvChange {
  constructor() {
    window.addEventListener('load', () => {
      const movie = document.getElementById('js-kv_movie')
      const target = document.querySelector('.p-index_service')
      if (!movie) return
      const gbcrTop = target.getBoundingClientRect().top
      const y = window.pageYOffset + gbcrTop
      window.addEventListener('scroll', (e) => {
        const top = window.scrollY
        if (top > y) {
          movie.classList.add('is-act')
        } else {
          movie.classList.remove('is-act')
        }
      })
    })

    document.addEventListener('DOMContentLoaded', () => {
      gsap.to('.js-kv_movie_img', {
        scale: '1',
        scrollTrigger: {
          trigger: '.p-index_kv', //アニメーションが始まるトリガーとなる要素
          start: 'top center', //アニメーションが始まる位置
          end: 'bottom center', //アニメーションが終わる位置
          scrub: 1, //スクロール量に合わせてアニメーションが進む（数字も指定できる）
        },
      })
    })
  }
}
