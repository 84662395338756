import 'keen-slider/keen-slider.min.css'
import KeenSlider from 'keen-slider'
import { Navigation } from './Navigation'
export class IndexFeedSlider {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      const youtubeSliderEl = document.getElementById('js-youtube_slider')
      const booksSliderEl = document.getElementById('js-books_slider')
      if (!youtubeSliderEl && !booksSliderEl) return
      const navigation = new Navigation('p-index_feed__arrow')
      const slider = new KeenSlider(
        youtubeSliderEl,
        {
          loop: true,
          slides: {
            perView: 1.5,
            spacing: 15,
          },
          breakpoints: {
            '(min-width: 768px)': {
              slides: {
                perView: 2.6,
                spacing: 15,
              },
            },
            '(min-width: 1025px)': {
              slides: {
                perView: 3.2,
                spacing: 30,
              },
            },
          },
          created(s) {
            s.on('slideChanged', (s) => {
              navigation.updateClasses(s)
            })
            navigation.markup(false, s)
            navigation.updateClasses(s)
          },

          updated(s) {
            navigation.updateClasses(s)
          },
        },
        [
          (slider) => {
            let timeout
            let mouseOver = false
            function clearNextTimeout() {
              clearTimeout(timeout)
            }
            function nextTimeout() {
              clearTimeout(timeout)
              if (mouseOver) return
              timeout = setTimeout(() => {
                slider.next()
              }, 2000)
            }
            slider.on('created', () => {
              slider.container.addEventListener('mouseover', () => {
                mouseOver = true
                clearNextTimeout()
              })
              slider.container.addEventListener('mouseout', () => {
                mouseOver = false
                nextTimeout()
              })
              nextTimeout()
            })
            slider.on('dragStarted', clearNextTimeout)
            slider.on('animationEnded', nextTimeout)
            slider.on('updated', nextTimeout)
          },
        ]
      )
      const slider2 = new KeenSlider(
        booksSliderEl,
        {
          loop: true,
          slides: {
            perView: 2.5,
            spacing: 15,
          },
          breakpoints: {
            '(min-width: 768px)': {
              slides: {
                perView: 4.2,
                spacing: 15,
              },
            },
            '(min-width: 1025px)': {
              slides: {
                perView: 5.5,
                spacing: 30,
              },
            },
          },
          created(s) {
            s.on('slideChanged', (s) => {
              navigation.updateClasses(s)
            })
            navigation.markup(false, s)
            navigation.updateClasses(s)
          },

          updated(s) {
            navigation.updateClasses(s)
          },
        },
        [
          (slider) => {
            let timeout
            let mouseOver = false
            function clearNextTimeout() {
              clearTimeout(timeout)
            }
            function nextTimeout() {
              clearTimeout(timeout)
              if (mouseOver) return
              timeout = setTimeout(() => {
                slider.next()
              }, 2000)
            }
            slider.on('created', () => {
              slider.container.addEventListener('mouseover', () => {
                mouseOver = true
                clearNextTimeout()
              })
              slider.container.addEventListener('mouseout', () => {
                mouseOver = false
                nextTimeout()
              })
              nextTimeout()
            })
            slider.on('dragStarted', clearNextTimeout)
            slider.on('animationEnded', nextTimeout)
            slider.on('updated', nextTimeout)
          },
        ]
      )
    })
  }
}
