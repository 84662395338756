export class HeaderSize {
  constructor() {
    const getSize = () => {
      const header = document.getElementById('js-header')
      if (!header) return
      const headerW = header.clientHeight
      document.documentElement.style.setProperty('--diff-size', headerW + 'px')
    }
    window.addEventListener('resize', getSize)
    document.addEventListener('DOMContentLoaded', getSize)
  }
}
