export class Navigation {
  constructor(arrowClass) {
    this.wrapper
    this.dots
    this.arrowLeft
    this.arrowRight
    this.arrowClass = arrowClass
  }

  markup(remove, slider) {
    this.wrapperMarkup(remove, slider)
    this.dotMarkup(remove, slider)
    this.arrowMarkup(remove, slider)
  }

  removeElement(elment) {
    elment.parentNode.removeChild(elment)
  }
  createDiv(className) {
    const div = document.createElement('div')
    const classNames = className.split(' ')
    classNames.forEach((name) => div.classList.add(name))
    return div
  }

  arrowMarkup(remove, slider) {
    if (remove) {
      this.removeElement(this.arrowLeft)
      this.removeElement(this.arrowRight)
      return
    }
    this.arrowWrap = this.createDiv(`${this.arrowClass}_wrap`)
    this.arrowLeft = this.createDiv(`${this.arrowClass} c-common_slide_arrow -left`)
    const arrow = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="js-svg replaced-svg"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="currentColor" d="M443.7 266.8l-165.9 176C274.5 446.3 269.1 448 265.5 448c-3.986 0-7.988-1.375-11.16-4.156c-6.773-5.938-7.275-16.06-1.118-22.59L393.9 272H16.59c-9.171 0-16.59-7.155-16.59-15.1S7.421 240 16.59 240h377.3l-140.7-149.3c-6.157-6.531-5.655-16.66 1.118-22.59c6.789-5.906 17.27-5.469 23.45 1.094l165.9 176C449.4 251.3 449.4 260.7 443.7 266.8z"></path></svg>`
    this.arrowLeft.innerHTML = arrow
    this.arrowLeft.addEventListener('click', () => slider.prev())
    this.arrowRight = this.createDiv(`${this.arrowClass} c-common_slide_arrow -right`)
    this.arrowRight.innerHTML = arrow
    this.arrowRight.addEventListener('click', () => slider.next())
    this.arrowWrap.appendChild(this.arrowLeft)
    this.arrowWrap.appendChild(this.arrowRight)
    this.wrapper.appendChild(this.arrowWrap)
  }

  wrapperMarkup(remove, slider) {
    if (remove) {
      const parent = this.wrapper.parentNode
      while (wrapper.firstChild) parent.insertBefore(wrapper.firstChild, wrapper)
      this.removeElement(wrapper)
      return
    }
    this.wrapper = this.createDiv('navigation-wrapper')
    slider.container.parentNode.appendChild(this.wrapper)
    this.wrapper.appendChild(slider.container)
  }

  dotMarkup(remove, slider) {
    if (remove) {
      this.removeElement(dots)
      return
    }
    this.dots = this.createDiv('c-common_dots p-index_kv__dots')
    slider.track.details.slides.forEach((_e, idx) => {
      const dot = this.createDiv('js-kv_dot dot')

      dot.addEventListener('click', () => slider.moveToIdx(idx))
      this.dots.appendChild(dot)
    })
    this.wrapper.appendChild(this.dots)
  }

  updateClasses(slider) {
    const slide = slider.track.details.rel
    slide === 0 ? this.arrowLeft.classList.add('-disabled') : this.arrowLeft.classList.remove('-disabled')
    slide === slider.track.details.slides.length - 1 ? this.arrowRight.classList.add('-disabled') : this.arrowRight.classList.remove('-disabled')
    Array.from(this.dots.children).forEach((dot, idx) => {
      idx === slide ? dot.classList.add('dot--active') : dot.classList.remove('dot--active')
    })
  }
}
