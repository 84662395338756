import { createApp } from 'vue/dist/vue.esm-bundler.js'
import axios from 'axios'
export class VueInterviewFilter {
  constructor() {
    this.el = document.getElementById('js-search_interview')
    if (this.el === null) return
    axios.get('/wp-json/wp/v2/custom_interview').then((res) => {
      createApp({
        data() {
          return {
            posts: res.data,
            interview_employee: vueInterviewEmployee,
            interview_cat: vueInterviewCat,
            interview_area: vueInterviewArea,
            interview_product: vueInterviewProduct,
          }
        },
        methods: {
          toggleCheck(item) {
            item.checked = !item.checked
          },
          clearFiltering() {
            this.interview_employee.forEach((term) => {
              term.checked = false
            })
            this.interview_cat.forEach((term) => {
              term.checked = false
            })
            this.interview_area.forEach((term) => {
              term.checked = false
            })
            this.interview_product.forEach((term) => {
              term.checked = false
            })
          },
        },
        mounted() {
          this.interview_employee.forEach((term) => {
            if (term.checked) {
              this.currentCat = term.slug
            }
          })
          this.interview_cat.forEach((term) => {
            if (term.checked) {
              this.currentCat = term.slug
            }
          })
          this.interview_area.forEach((term) => {
            if (term.checked) {
              this.currentCat = term.slug
            }
          })
          this.interview_product.forEach((term) => {
            if (term.checked) {
              this.currentCat = term.slug
            }
          })
        },
        computed: {
          filteredPosts() {
            let posts = this.posts
            // フィルタリングの部分を関数にして呼ぶ
            const filtering = (post, keyStr, slugType) => {
              let isChecked = false
              let isDefault = true
              this[keyStr].forEach((cat) => {
                if (cat.checked) {
                  isDefault = false
                  if (post[slugType].includes(cat.slug)) {
                    isChecked = true
                  }
                }
              })
              return isDefault || isChecked
            }

            posts = posts.filter((post) => filtering(post, 'interview_employee', 'employee_slug'))
            posts = posts.filter((post) => filtering(post, 'interview_area', 'area_slug'))
            posts = posts.filter((post) => filtering(post, 'interview_cat', 'cat_slug'))
            posts = posts.filter((post) => filtering(post, 'interview_product', 'product_slug'))

            return posts.length
          },
        },
      }).mount(this.el)
    })
  }
}
