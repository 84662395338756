import { gsap } from 'gsap'

/**
 * ドロワーメニューの開閉機能
 */
export class Drawer {
  constructor() {
    this.hamburger = document.getElementById('js-hamburger')
    this.drawer = document.getElementById('js-drawer')
    if (this.hamburger === null || this.drawer === null) return

    this.hamburger.addEventListener('click', () => {
      this.hamburger.querySelector('span').classList.toggle('hidden')
      this.hamburger.classList.toggle('is-act')
      document.body.classList.toggle('is-act')
      if (this.hamburger.classList.contains('is-act')) {
        this.animation(this.drawer, 'open')
      } else {
        this.animation(this.drawer, 'close')
      }
    })

    this.drawerAcco()
  }
  animation(el, type) {
    if (type == 'open') {
      gsap.to(el, 0.3, { autoAlpha: 1, display: 'block' })
    } else {
      gsap.to(el, 0.3, { autoAlpha: 0, display: 'none' })
    }
  }

  drawerAcco() {
    const acoTrgs = document.querySelectorAll('.js-drawer_aco_trg')
    const acoTrgArr = Array.prototype.slice.call(acoTrgs, 0)

    acoTrgArr.forEach(function (target) {
      target.addEventListener('click', (event) => {
        const target = event.currentTarget
        const content = target.nextElementSibling
        const contentHeight = content.scrollHeight
        target.classList.toggle('is-act')
        if (target.classList.contains('is-act')) {
          content.classList.add('is-show')
          content.style.height = contentHeight + 'px'
        } else {
          content.style.height = null
          content.classList.remove('is-show')
        }
      })
    })
  }
}
