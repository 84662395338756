import 'keen-slider/keen-slider.min.css'
import KeenSlider from 'keen-slider'
import { Navigation } from './Navigation'
export class KvTopicsSlider {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      const sliderEl = document.getElementById('js-kv_topics_slider')
      if (!sliderEl) return
      const navigation = new Navigation('p-index_kv__arrow')
      const slider = new KeenSlider(
        sliderEl,
        {
          loop: true,
          slides: {
            perView: 1,
            spacing: 0,
          },

          created(s) {
            s.on('slideChanged', (s) => {
              navigation.updateClasses(s)
            })
            navigation.markup(false, s)
            navigation.updateClasses(s)
          },

          updated(s) {
            navigation.updateClasses(s)
          },
        },
        [
          (slider) => {
            let timeout
            let mouseOver = false
            function clearNextTimeout() {
              clearTimeout(timeout)
            }
            function nextTimeout() {
              clearTimeout(timeout)
              if (mouseOver) return
              timeout = setTimeout(() => {
                slider.next()
              }, 2000)
            }
            slider.on('created', () => {
              slider.container.addEventListener('mouseover', () => {
                mouseOver = true
                clearNextTimeout()
              })
              slider.container.addEventListener('mouseout', () => {
                mouseOver = false
                nextTimeout()
              })
              nextTimeout()
            })
            slider.on('dragStarted', clearNextTimeout)
            slider.on('animationEnded', nextTimeout)
            slider.on('updated', nextTimeout)
          },
        ]
      )
    })
  }
}
