export class flipCard {
  constructor() {
    if (document.getElementsByClassName('js-flip_card').length == 0) return
    const cardEl = Array.prototype.slice.call(document.getElementsByClassName('js-flip_card'), 0)

    cardEl.forEach((card) => {
      card.addEventListener('click', () => {
        card.classList.toggle('is-flip')
      })
    })
  }
}
