'use strict'

// import { IeModal } from './lib/IeModal'
// import { CookiePolicy } from './lib/CookiePolicy'
import { Svg } from './lib/Svg'
import { TelGrant } from './lib/TelGrant'
import { Smooth } from './lib/Smooth'
import { Drawer } from './lib/Drawer'
import { ScrollStatus } from './lib/ScrollStatus'
import { ScrollAnimation } from './lib/ScrollAnimation'
import { HeaderSize } from './lib/HeaderSize'
import { StickySection } from './lib/StickySection'
import { KvTopicsSlider } from './lib/KvTopicsSlider'
import { IndexInterviewSlider } from './lib/IndexInterviewSlider'
import { Accordion } from './lib/Accordion'
import { IndexFeedSlider } from './lib/IndexFeedSlider'
import { IndexFeedText } from './lib/IndexFeedText'
import { KvChange } from './lib/KvChange'
import { IndexCompanyBg } from './lib/IndexCompanyBg'
import { TextSplit } from './lib/TextSplit'
import { SpButtonMove } from './lib/SpButtonMove'
import { CountUp } from './lib/CountUp'
import { OverviewAccordion } from './lib/OverviewAccordion'
import { FixedSlideMenu } from './lib/FixedSlideMenu'
import { PhilosophyFixedBg } from './lib/PhilosophyFixedBg'
import { PhilosophyStickySlider } from './lib/PhilosophyStickySlider'
// import { GetYoutube } from './lib/GetYoutube'
import { AccordionSimple } from './lib/AccordionSimple'
import { OfficeSlider } from './lib/OfficeSlider'
import { Modal } from './lib/Modal'
import { Megamenu } from './lib/Megamenu'
import { Toc } from './lib/Toc'
import { VueSeminarFilter } from './lib/VueSeminarFilter'
import { VueInterviewFilter } from './lib/VueInterviewFilter'
import { flipCard } from './lib/flipCard'
import { FormSelectChoice } from './lib/FormSelectChoice'
import { GetColor } from './lib/GetColor'
import { ScrollSp } from './lib/ScrollSp'

// new IeModal('サイト名', 'http://hoge.com')
// new CookiePolicy('optout')
new Svg()
new TelGrant()
new Smooth()
new Drawer()
new ScrollStatus()
new ScrollAnimation()
new HeaderSize()
new StickySection()
new KvTopicsSlider()
new IndexInterviewSlider()
new Accordion()
new IndexFeedSlider()
new IndexFeedText()
new KvChange()
new IndexCompanyBg()
new TextSplit()
new SpButtonMove()
new CountUp()
new OverviewAccordion()
new FixedSlideMenu()
new PhilosophyFixedBg()
new PhilosophyStickySlider()
// new GetYoutube()
new AccordionSimple()
new OfficeSlider()
new Modal()
new Megamenu()
new Toc()
new VueSeminarFilter()
new VueInterviewFilter()
new flipCard()
new FormSelectChoice()
new GetColor()
new ScrollSp()
