import { UaCh } from '../foundation/UserAgent'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export class PhilosophyFixedBg {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      if (UaCh.isMobile) return
      gsap.to('.js-philosophy_bg', {
        y: -100,
        scrollTrigger: {
          trigger: '.js-philosophy_section', //アニメーションが始まるトリガーとなる要素
          start: 'top center', //アニメーションが始まる位置
          end: 'bottom center', //アニメーションが終わる位置
          scrub: 2, //スクロール量に合わせてアニメーションが進む（数字も指定できる）
        },
        stagger: {
          from: 'start', //左からアニメーション start、center、edges、random、endが指定できる
          amount: 0.1, //どのくらいの割合でズラすか
        },
      })
    })
  }
}
