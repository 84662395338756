import { UaCh } from '../foundation/UserAgent'
class Sticky {
  constructor(el) {
    this.el = el
    this.el.style.position = 'sticky'
    this.el.style.top = 0
    this.clientHeight = 0
  }

  update(innerHeight, i) {
    if (this.clientHeight === this.el.clientHeight) return
    this.clientHeight = this.el.clientHeight
    if (i === 0) {
      this.el.style.top = '0px'
    } else {
      this.el.style.top = i * 30 + 'px'
    }
  }
}
export class StickySection {
  constructor() {
    const mq = window.matchMedia('(min-width: 1024px)').matches //スマホ判定
    if (document.getElementsByClassName('js-sticky-section').length === 0) return
    if (!UaCh.isMobile && mq) {
      this.init([...document.getElementsByClassName('js-sticky-section')])
    }
  }
  init(sectionsEl) {
    const sections = sectionsEl.map((section) => new Sticky(section))
    let timer = 0
    const loop = () => {
      timer = requestAnimationFrame(loop)
      const innerHeight = window.innerHeight
      sections.forEach((section, i) => {
        section.update(innerHeight, i)
      })
    }
    loop()
  }
}
