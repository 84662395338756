import { UaCh } from '../foundation/UserAgent'
export class SpButtonMove {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      const mediaQuery = window.matchMedia('(min-width: 768px)')
      if (!UaCh.isMobile || mediaQuery.matches) return
      const buttons = document.querySelectorAll('.js-move_button')
      if (buttons.length === 0) return
      buttons.forEach((button) => {
        button.classList.add('-center', 'u-mt20')
        const parent = button.closest('.js-button_move_wrap')
        parent.appendChild(button)
      })
    })
  }
}
