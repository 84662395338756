import 'keen-slider/keen-slider.min.css'
import KeenSlider from 'keen-slider'
// import { Navigation } from './Navigation'
export class OfficeSlider {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      const sliderEl = document.querySelectorAll('.js-office_slider')
      if (!sliderEl.length === 0) return
      sliderEl.forEach((elm) => {
        const slider = new KeenSlider(elm, {
          loop: true,
          slides: {
            perView: 1.4,
            spacing: 10,
          },
          breakpoints: {
            '(min-width: 768px)': {
              slides: {
                perView: 2.4,
                spacing: 30,
              },
            },
            '(min-width: 1024px)': {
              slides: {
                perView: 3.4,
                spacing: 30,
              },
            },
          },
          slideChanged: (s) => {
            const rel = s.track.details.rel
            s.slides.forEach((element, idx) => {
              element.classList.remove('is-act')
              if (idx === rel) {
                element.classList.add('is-act')
              }
            })
          },
        })
      })
    })
  }
}
