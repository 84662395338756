import { UaCh } from '../foundation/UserAgent'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export class IndexCompanyBg {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      const mq = window.matchMedia('(min-width: 1024px)').matches //タブレット以下判定
      if (UaCh.isMobile && !mq) return
      gsap.to('.js-company_bg', {
        y: -200,
        scrollTrigger: {
          trigger: '.js-company_section', //アニメーションが始まるトリガーとなる要素
          start: 'top center', //アニメーションが始まる位置
          end: 'bottom center', //アニメーションが終わる位置
          scrub: 2, //スクロール量に合わせてアニメーションが進む（数字も指定できる）
        },
        stagger: {
          from: 'start', //左からアニメーション start、center、edges、random、endが指定できる
          amount: 0.1, //どのくらいの割合でズラすか
        },
      })
    })
  }
}
